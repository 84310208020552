
/* .navy { color: #001F3F; }
.blue { color: #0074D9; }
.aqua { color: #7FDBFF; }
.teal { color: #39CCCC; }
.olive { color: #3D9970; }
.green { color: #2ECC40; }
.lime { color: #01FF70; }
.yellow { color: #FFDC00; }
.orange { color: #FF851B; }
.red { color: #FF4136; }
.fuchsia { color: #F012BE; }
.purple { color: #B10DC9; }
.maroon { color: #85144B; }
.white { color: #FFFFFF; }
.silver { color: #DDDDDD; }
.gray { color: #AAAAAA; }
.black { color: #111111; } */
@import url(./forks/fonts/stylesheet.css);
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
/* @import url(./dndStyles.css); */

.page table tbody tr:nth-child(odd){
    background-color: #e0e5c1;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

button {
  font-family: inherit;
}

html {
    font-family: 'Bookinsanity';
    /* min-height: -webkit-fill-available; */
}

body {
  /* height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available; */
  background-color: #EEE5CE;
  background-image: url("https://homebrewery.naturalcrit.com/assets/parchmentBackground.jpg");
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word;
  overflow: -moz-scrollbars-vertical; 
  overflow-y: overlay;
  color: black;
}

a{
    color: #0a81e9;
}

.mainWrapper {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.richEditor{
    border: 1px solid gray;
    min-height: 6em;
}

/* Style inputs, select elements and textareas */
input, select, textarea{
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  border: 0px solid #d7dbdb;
  box-sizing: border-box;
  resize: vertical;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  color:rgba(255, 255, 255, 0.87);
}

[contentEditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
  color:grey;
  font-style:italic;
}


input.newNoteUrl{
  padding: 12px;
  border-bottom: thin solid #ccc;
}


/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

::placeholder{
    color: rgba(255,255,255,.6);
}

/* ----------------------------
      SCROLLBAR
---------------------------- */

::-webkit-scrollbar{
  width: 10px;
  background-clip: padding-box
  /* height: 90%; */
}
/* 
::-webkit-scrollbar-track {
  border-radius: 10px;
} */

::-webkit-scrollbar-track-piece{
  /* background-color: #FFF; */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
	background-color: #424242;
	outline-offset: -2px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #585858;
  border-radius: 10px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

.fade-out {
	opacity: 0;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

.fade-out-1s {
	opacity: 0;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

@keyframes scaleIn {
    from {
        transform: scale(0.1);
    }
    to {
        transform: scale(1);
    }
}

@keyframes run {
    0% {
      left: 0;
    }
    48% {
      -webkit-transform: rotateY(0deg); 
    }
    50% { 
      left: calc(100%);
      -webkit-transform: rotateY(180deg); 
      opacity: 0%
    }
    98% {
      -webkit-transform: rotateY(180deg); 
    }
    100% {
      left: 0;    
       -webkit-transform: rotateY(0deg);
    }
  }

  .fa-fast-spin {
    -webkit-animation: fa-spin .5s infinite linear;
    animation: fa-spin .5s infinite linear;
  }